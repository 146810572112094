
/* Add these styles to your existing CSS */

.login-page-container {
  max-width: 400px;
  padding: 40px;
  background-color:lightblue;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 150px auto; /* Center the container vertically and horizontally */
  text-align: center; /* Center the contents horizontally */
}

.login-page-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.login-page-container p {
  font-size: 14px;
  color: #666;
}

.login-page-container .login-button {
  padding: 12px 24px;
  background-color: #1b88a1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-page-container .login-button:hover {
  background-color: #176f87;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-content p {
  margin: 0 0 10px;
}

.popup-content button {
  cursor: pointer;
}
