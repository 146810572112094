.unit-container {
    display: flex;
    border: #333;
  }
  
  .container{
    text-align: center;
    
  }
  
  .units-section {
    flex: 4;
  }
  
  .unit-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .unit-list {
    list-style-type: none;
    padding: 0;
  }
  
  .unit-item {
    background-color: #A2CDB0;
    padding: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
  }
  
  .unit-item:hover {
    background-color:lightgrey;
  }
  
  
  .unit-item.active {
    background-color: lightgreen;
    color:black;
  }
  
  .topics-section {
    flex: 6;
    margin-left: 20px;
  }
  
  .topic-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .topic-list {
    list-style-type: none;
    padding: 0;
  }
  .link-style{
    text-decoration: none;
    color: black;
  }
  
  .topic-item {
    background-color: lightgrey;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .topic-item:hover{
    cursor: pointer;
    background-color: lightblue;
  }