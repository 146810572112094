
body{
  background-color:#F1F6F9;
}

.course_container{
  background-color: #0056b3;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 25px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  /* Button styling */
  button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Disabled button styling */
  .disabled-button {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  /* Students list styling */
  .students-list {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f5f5f5;
    max-height:20vh ; /* Set the maximum height as needed */
    overflow-y: auto;
  }
  .students-list-container {
    max-height: auto; /* Set the maximum height as needed */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
  }
  .students_text{
    position: sticky;
  }
  
  .students-list h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .students-list ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .students-list li {
    margin-bottom: 8px;
  }
  
  /* Start class button styling */
  .start-class {
    margin-top: 20px;
    text-align: center;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    table {
      font-size: 14px;
    }
  }
  
  /* Course row styling */
  .course-row {
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .course-row:hover {
    background-color: lightblue; /* Light gray background color on hover */
    cursor: pointer; /* Change the cursor to a pointer on hover */
  }
  
  /* Selected course row styling */
  .selected-course {
    background-color: #e0e0e0;
  }
  
  /* View students button styling */
  .view-students-button {
    margin: 0;
  }
  
  /* Make the button stick to the bottom of the course page */
  /* And make it stay right after the table ends */
  .start-class::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
  