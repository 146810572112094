.live-responses-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 75vh;
  max-height: 70vh;
}

.bar-graphs {
  flex: 8;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.question-section {
  flex: 4;
  margin-right: 10px;
  padding: 10px;
  margin-top: 0;
  height: 65vh;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  border: 1px dotted black;
}

.question-section.sticky {
  position: sticky;
  top: 0;
}

.question-section .option-list {
  justify-content: center;
}

.question-section .option-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: auto;
  background-color: lightblue;
  transition: background-color 0.3s, cursor 0.3s;
  cursor: pointer;
  font-family: "Arial", sans-serif;
}

.question-section .option-item:hover {
  background-color: #f2f2f2;
}

.option-container {
  display: flex;
  align-items: center;
}

.option-container span {
  margin-right: 10px;
}

.bar-graph {
  margin: 10px;
  width: auto;
  flex: 1;
}

.option-item.correct-answer {
  background-color: lightgreen;
}

@media screen and (max-width: 768px) {
  .action-button {
    padding: 10px 20px;
  }
}

.button-row {
  margin-top: 0;
}

.button-row .action-button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.button-row .action-button:hover {
  background-color: white;
}

.display-answer-button {
  background-color: #4CAF50;
  color: black;
  font-weight: bold;
  display: flex;
  width: auto;
  background-color: #4CAF50;
  justify-content: center;
  border-radius: 5px;
}

.button-container {
  display: flex;
  align-items: center;
}

.display_btn_container,
.nextquestion {
  margin-right: 10px;
}

.display-answer-button:hover {
  background-color: grey;
}

.stop-question-button {
  background-color: #f44336;
  color: black;
  padding: 20px;
}

.repost-question-button {
  background-color: lightgrey;
  color: black;
}

.nextquestion {
  position: fixed;
  bottom: 5%;
  left: 50%;
}

.next-question-button {
  color: white;
  font-weight: bold;
  display: flex;
  background-color: #007bff;
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.next-question-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.disabled-button {
  opacity: 0.5;
}

.word-cloud-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}
