


.report {
    font-family: Arial, sans-serif;
    
    /* padding: 20px; */
    max-height: 100vh;
    margin-top: 0;
    /* overflow-y:hidden; */
}

.heading {
    text-align: center;
    margin-top: 0;
    /* margin-bottom: 20px; */
}
.class-details{
    border: 2px solid grey;
    max-height:30vh;
}

.head {
    display:flex;
    padding:10px;
} 
.components{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius:5px;
    background-color:lightblue;
    margin-right: 2px;
}
.detail-item {
    margin-bottom: 10px;
}

.detail-label {
    font-weight: bold;
}

.detail-value {
    margin-left: 10px;
    overflow: visible;
   
} 

.student-scores {
    border: 2px solid gray;
    padding: 10px;
    margin-bottom: 20px;
    overflow-y: auto;
    position: relative; /* Establish stacking context for sticky */
    max-height:40vh;
}

.student-scores .head {
    position: sticky;
    top: 0;
    /* background-color: white; Optional: Ensure it's readable */
    z-index: 1; /* Ensure it's above other content */
}/*
.student-scores {
    border: 2px solid gray;
    margin-bottom: 20px;
    overflow-y:auto;
    max-height:50vh;
}

.student-scores h2 {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px;
    margin: 0;
    z-index: 1;
}*/
.scores{
    display:flex;
    /* margin-left:10px; */
    justify-content:space-evenly;
}

.ok-button {
    text-align: center;
    margin-top: 20px;
}

.ok-button button {
    background-color: red;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.ok-button button:hover {
    background-color: #0056b3;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader {
    border: 16px solid lightblue;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-text {
    position: fixed;
    margin-top: 20px;
}