.profile {
    position: absolute;
    top: 0px;
    right: 20px;
    cursor: pointer;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .profile-popup {
    position: absolute;
    top: 60px;
    right: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    animation: fade-in 0.3s ease;
  }
  
  .profile-popup h3 {
    margin-top: 0;
  }
  
  .profile-popup p {
    margin-bottom: 10px;
  }
  
  .profile-popup button {
    padding: 8px 12px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  /*--------------------*/
  
  .content {
    padding: 20px;
  }
  
  h2,
  h3 {
    margin-top: 0;
  }