.page-container {
    display: flex;
    height: 80vh;
   
  }
  
  .questions-container {
    flex: 4;
    padding:10px;
    overflow-y: auto;
    height:85vh;
    
  }
  
  .question-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
  }
  
  .question-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border:1px solid black;
    background-color: lightblue;
    /* background-color: #A2CDB0; */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .question-item:hover{
    background-color: lightgrey;
  }
  
  .information-container {
    flex: 6;
    margin-left: 20px;
    margin-top:20px;
  }
  
  .question-details {
    /* border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    background-color:white; */
    flex: 4; /* Take 4 flex units */
    margin-right: 10px;
    padding: 10px;
    margin-top:0px;
    height:70vh;
    max-height: 70vh; /* Set a maximum height for the question details */
    overflow-y: auto;
    position: relative;
    /* Add some spacing between question and bar graph */
    border:1px dotted black;
    
  }
  .question-details.sticky {
    position: sticky;
    top: 0;
  }
  
  
  .question-details .question-image {
    width: 300px;
    height: auto;
    margin-left:20px;
    object-fit: cover;
    
   
  }
  
  .question-details .option-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    margin-top: 20px;
  }
  
  .question-details .option-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: 48%; /* Adjust the width as needed */
    background-color: lightblue;
    transition: background-color 0.3s, cursor 0.3s; /* Add transition for smooth hover effect */
    cursor: pointer;
    font-family: "Arial", sans-serif; 
  }
  .question-details .option-item:hover {
    background-color: #f2f2f2; /* Change the background color on hover */
  }
  
  
  .question-details .option-image {
    width: 120px;
    height: auto;
    object-fit: cover;
    
  }
  
  
  /* New CSS for the acknowledgment message */
  .acknowledgment-message {
    background-color: lightgreen;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    animation-name: fadeOut;
    animation-duration: 10s;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  
  .button-container {
    position:sticky;
    bottom:10px;
    left:10px;
    right:10px;
    display:flex;
    justify-content: space-between;
  }
  .active-question{
    background-color: lightgreen;
  }
  
  .action-button {
    background-color:lightgreen;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    margin:10px;
    color:black;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #dcdcdc;
  }
  
  #post_question{
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    margin:10px;
    transition: background-color 0.3s ease;
  
  }
  
  .endclass-button {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex; /* Use flexbox to make the icon and text align side by side */
    align-items: center; /* Align items vertically in the center */
    background-color: rgb(224, 14, 14);
    border: none;
    color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 10px 15px; /* Adjust padding for better spacing */
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Style the Material Icons icon */
  .endclass-button .material-icons {
    font-size: 20px; /* Adjust the icon size as needed */
    margin-right: 5px; /* Add some spacing between the icon and text */
  }
  
  .endclass-button:hover {
    background-color: rgba(250, 80, 80, 0.897);
  }
  
  /* Make the button stick to the bottom of the page */
  .endclass-button::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }